import React, { useEffect } from 'react'
import {
  List,
  Drawer,
  Divider,
  Tooltip,
  Collapse,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useNavigate, useLocation } from 'react-router-dom'

import routes from '../../routes'
import { useUser } from '../../context/user.context'
import { validatePrivilegesList } from '../../utils/privilegeValidator'

import Brand from './brand'
import styles from './styles'
import getIcon from './iconsHandler'

const Sidebar = ({
  open,
  menu,
  classes,
  setOpen,
  menuState,
  setMenuState,
  panelSelected,
  setPanelSelected
}) => {
  const style = styles()
  const [currentUser] = useUser()
  const navigate = useNavigate()
  const location = useLocation()
  const isTablet = useMediaQuery({ query: '(min-width: 600px)' })
  const onSelectOption = item => {
    setPanelSelected(item?.title)

    if (item.id) setMenuState(item?.id)
  }

  const onPressMenuOption = item => {
    if (PressedMenuOption(item)) navigate(item?.path)
  }

  const PressedMenuOption = item => {
    if (item?.title !== panelSelected) {
      item?.submenu?.length > 0 ? setMenuState(item?.id) : onSelectOption(item)

      if (isTablet) {
        setOpen(true)
      } else {
        setOpen(false)
      }

      return true
    }

    return false
  }

  useEffect(() => {
    const route = routes.find(route =>
      location?.pathname.includes(route?.path?.split('/')[1])
    )

    PressedMenuOption(route)
  }, [location])

  return (
    <Drawer
      className={open ? classes.drawer : classes.drawerShort}
      variant='persistent'
      anchor='left'
      open={true}
      classes={{
        paper: open ? classes.drawerPaper : classes.drawerPaperShort
      }}
    >
      <Divider />
      <List className={style.listPadding}>
        {menu.map(item => {
          if (
            item.enable &&
            validatePrivilegesList(item?.privileges, currentUser?.privileges)
          ) {
            return (
              <div key={item.id}>
                <ListItem
                  className={
                    menuState[item.id]
                      ? item.submenu.length > 0
                        ? classes.mainItemActive
                        : classes.mainItemActiveSelected
                      : classes.mainItem
                  }
                  key={item.id}
                >
                  <Tooltip title={item.title}>
                    <ListItemIcon
                      onClick={() => onPressMenuOption(item)}
                      className={style.sidebarIconStyle}
                    >
                      {menuState &&
                        getIcon(
                          item?.iconName,
                          menuState[item?.id]
                            ? item?.submenu?.length > 0
                              ? classes?.mainItemIconActive
                              : classes?.mainItemIconActiveSelected
                            : classes?.mainItemIcon,
                          style
                        )}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type='body2'
                        className={
                          menuState[item.id]
                            ? item.submenu.length > 0
                              ? classes.mainItemText
                              : classes.mainItemTextSelected
                            : classes.mainItemText
                        }
                      >
                        {item?.title?.toUpperCase()}
                      </Typography>
                    }
                    onClick={() => onPressMenuOption(item)}
                  />
                  {item.submenu.length > 0 &&
                    (menuState[item.id] ? (
                      <ExpandLess
                        className={classes.mainItemExpandIcon}
                        onClick={() => setMenuState(item.id)}
                      />
                    ) : (
                      <ExpandMore
                        className={classes.mainItemExpandIcon}
                        onClick={() => setMenuState(item.id)}
                      />
                    ))}
                </ListItem>
                {item.submenu.length > 0 && (
                  <Collapse
                    in={menuState[item.id]}
                    timeout='auto'
                    unmountOnExit
                  >
                    <List
                      component='div'
                      disablePadding
                      className={classes.subMenuItem}
                    >
                      {item.submenu.map((option, index) => {
                        if (option.enable) {
                          return (
                            <ListItem
                              key={index}
                              path={item?.path}
                              className={
                                option.title === panelSelected
                                  ? classes.subMenuItemSelected
                                  : classes.nested
                              }
                            >
                              <ListItemText
                                disableTypography
                                primary={
                                  <Typography
                                    type='body2'
                                    className={
                                      option.title === panelSelected
                                        ? classes.subMenuItemTextSelected
                                        : classes.subMenuItemText
                                    }
                                  >
                                    {option.title}
                                  </Typography>
                                }
                                onClick={() => {
                                  onSelectOption(option),
                                    onPressMenuOption(option)
                                }}
                              />
                            </ListItem>
                          )
                        }
                      })}
                    </List>
                  </Collapse>
                )}
              </div>
            )
          }
        })}
        <ListItem className={style.removePadding} key='brand'>
          <Brand open={open} />
        </ListItem>
      </List>
    </Drawer>
  )
}

Sidebar.propTypes = {
  menu: PropTypes.array,
  classes: PropTypes.object,
  menuState: PropTypes.object,
  setMenuState: PropTypes.func,
  setPanelSelected: PropTypes.func,
  handleDrawerClose: PropTypes.func,
  open: PropTypes.bool
}

export default Sidebar
