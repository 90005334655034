import React from 'react'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { Box, Popover, Typography } from '@mui/material'

import useStyles from './styles'

const NotificationContainer = ({
  handleCloseActions,
  notifications,
  handleGoto,
  anchorEl,
  open,
  id
}) => {
  const classes = useStyles()

  return (
    <div className={classes.walletContainer}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        onClose={handleCloseActions}
      >
        <Box bgcolor='#FFFFFF' maxWidth={280}>
          {notifications?.map((notification, index) => (
            <Box
              pl={1}
              pr={2}
              width='100%'
              paddingY={1}
              borderBottom='solid 1px grey'
              className={classes.notifyItem}
              onClick={() => handleGoto(notification)}
              key={`${index}-${notification?.description}`}
              style={{
                backgroundColor: notification?.seen
                  ? '#FFFFFF'
                  : 'rgba(255, 183, 27, 0.2)'
              }}
            >
              <Typography variant='body1'>{notification?.title}</Typography>
              <Typography
                variant='body2'
                style={{ paddingTop: 8, paddingBottom: 8 }}
              >
                {notification?.description}
              </Typography>
              <Typography variant='caption'>
                {format(
                  new Date(notification?.date),
                  "EEEE dd 'de' MMMM 'de' yyyy 'a las' HH:mm",
                  { locale: es }
                )}
              </Typography>
            </Box>
          ))}
        </Box>
      </Popover>
    </div>
  )
}

export default NotificationContainer
