import { lazy } from 'react'

const User = lazy(() => import('./User'))
const Reset = lazy(() => import('./Reset'))
const Login = lazy(() => import('./Login'))
const Diary = lazy(() => import('./Diary'))
const Forgot = lazy(() => import('./Forgot'))
const Minute = lazy(() => import('./Minutes'))
const Session = lazy(() => import('./Session'))
const Dashboard = lazy(() => import('./Login'))
const Page404 = lazy(() => import('./Route404'))
const Atendance = lazy(() => import('./Atendance'))
const Agreement = lazy(() => import('./Agreement'))
const Transcribe = lazy(() => import('./Transcribe'))
const UserProfile = lazy(() => import('./User/Profile'))
const Announcement = lazy(() => import('./Announcement'))
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'))
const TermsConditions = lazy(() => import('./TermsConditions'))
const Parameterization = lazy(() => import('./Parameterization'))
const FastTranscription = lazy(() => import('./Transcribe/FastTranscription'))

export default [
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: Dashboard,
    exact: true,
    id: 'principal',
    title: 'Panel Principal',
    submenu: [],
    iconName: 'AppsIcon',
    privileges: []
  },
  {
    name: 'agend',
    path: '/diary/:sessionId',
    id: 'agend',
    component: Diary,
    exact: true,
    title: 'Agendas',
    submenu: [],
    privileges: ['ver_agendas']
  },
  {
    name: 'announcement',
    path: '/announcement/:sessionId',
    component: Announcement,
    id: 'announcement',
    exact: true,
    title: 'Convocatorias',
    submenu: [],
    privileges: ['ver_convocatoria']
  },
  {
    name: 'transcription',
    path: '/transcription/:sessionId',
    component: Transcribe,
    exact: true,
    id: 'transcription',
    title: 'Transcripción',
    submenu: [
      {
        id: 'transcription',
        title: 'Por Sesión',
        component: Transcribe,
        path: '/transcription',
        enable: true,
        privileges: ['ver_transcripciones']
      },
      {
        id: 'fastTranscript',
        title: 'Rapida',
        path: '/transcription-fast',
        component: FastTranscription,
        enable: true,
        privileges: ['ver_transcripciones']
      }
    ],
    privileges: ['ver_transcripciones']
  },
  {
    name: 'minutes',
    path: '/minute/:sessionId',
    component: Minute,
    exact: true,
    id: 'minutes',
    title: 'Actas',
    submenu: [],
    privileges: ['ver_actas']
  },
  {
    name: 'Agreements',
    path: '/agreements/:sessionId',
    id: 'agreements',
    component: Agreement,
    exact: true,
    title: 'Acuerdos',
    submenu: [],
    privileges: ['ver_acuerdos']
  },
  {
    id: 'session',
    name: 'session',
    path: '/session',
    component: Session,
    exact: true,
    title: 'Sesiones',
    privileges: ['ver_sesiones'],
    submenu: [],
    enable: true,
    iconName: 'ForumIcon'
  },
  {
    name: 'agend',
    path: '/diary',
    component: Diary,
    exact: true,
    id: 'agend',
    title: 'Agendas',
    submenu: [],
    iconName: 'EventIcon',
    enable: true,
    privileges: ['ver_agendas']
  },
  {
    name: 'announcement',
    path: '/announcement',
    component: Announcement,
    exact: true,
    id: 'announcement',
    title: 'Convocatorias',
    submenu: [],
    iconName: 'campaignIcon',
    enable: true,
    privileges: ['ver_convocatoria']
  },
  {
    name: 'transcription',
    exact: true,
    id: 'transcription',
    title: 'Transcripción',
    submenu: [
      {
        id: 'transcription',
        title: 'Por Sesión',
        component: Transcribe,
        path: '/transcription',
        enable: true,
        privileges: ['ver_transcripciones']
      },
      {
        id: 'fastTranscript',
        title: 'Individual',
        path: '/transcription-fast',
        component: FastTranscription,
        enable: true,
        privileges: ['ver_transcripciones']
      }
    ],
    iconName: 'transcribeIcon',
    enable: true,
    privileges: ['ver_transcripciones']
  },
  {
    name: 'minutes',
    path: '/minute',
    component: Minute,
    exact: true,
    id: 'minutes',
    title: 'Actas',
    submenu: [],
    iconName: 'actasIcon',
    enable: true,
    privileges: ['ver_actas']
  },
  {
    name: 'Agreements',
    path: '/agreements',
    component: Agreement,
    exact: true,
    id: 'agreements',
    title: 'Acuerdos',
    submenu: [],
    iconName: 'acuerdosIcon',
    enable: true,
    privileges: ['ver_acuerdos']
  },
  {
    name: 'users',
    path: '/user',
    component: User,
    exact: true,
    id: 'users',
    title: 'Usuarios',
    submenu: [],
    iconName: 'SupervisorAccountIcon',
    enable: true,
    privileges: ['ver_usuarios']
  },
  {
    name: 'parameterization',
    path: '/parameterization',
    component: Parameterization,
    exact: true,
    id: 'parameterization',
    title: 'Parametrización',
    submenu: [],
    iconName: 'SettingsIcon',
    enable: true,
    privileges: ['ver_parametrizacion', 'ver_comites', 'ver_roles']
  },
  {
    name: 'Login',
    path: '/',
    component: Login,
    exact: true
  },
  {
    name: 'Terms Conditions',
    path: '/terms-conditions',
    component: TermsConditions,
    exact: true
  },
  {
    name: 'Privacy Policy',
    path: '/privacy-policy',
    component: PrivacyPolicy,
    exact: true
  },
  {
    name: 'Forgot',
    path: '/change-password',
    component: Forgot,
    exact: true
  },
  {
    name: 'Reset',
    path: '/reset-password',
    component: Reset,
    exact: true
  },
  {
    name: 'User Profile',
    path: '/profile',
    component: UserProfile,
    exact: true
  },
  {
    name: 'Atendance',
    path: '/atendance',
    component: Atendance,
    exact: true
  },
  {
    component: Page404
  }
]
