import React from 'react'
import { CircularProgress } from '@mui/material'

import styles from './styles'

const Loader = () => {
  const classes = styles()

  return (
    <div className={classes.root}>
      <CircularProgress m={2} className={classes.loader} size={75} />
    </div>
  )
}

export default Loader
