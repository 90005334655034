const formatDate = date => {
  if (!date) return

  const arrayDate = date?.split('-')
  const newDate = arrayDate[2] + '/' + arrayDate[1] + '/' + arrayDate[0]

  return newDate
}

const formartDateWithTimeZone = date =>
  new Date(date)?.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })

export { formatDate, formartDateWithTimeZone }
