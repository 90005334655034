import palette from './palette'

export default {
  fontFamily: 'Rubik',
  h1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '2.9em',
    letterSpacing: '-0.24px'
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.8125em',
    letterSpacing: '-0.24px'
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.50em',
    letterSpacing: '-0.06px'
  },
  h4: {
    color: palette.text.primary,
    fontSize: '1em',
    letterSpacing: '-0.06px',
    fontWeight: 'bold'
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 'bold',
    fontSize: '1.15em !important',
    letterSpacing: '-0.05px'
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 'bold',
    fontSize: '0.875em',
    letterSpacing: '-0.05px'
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '1em',
    letterSpacing: '-0.05px'
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '0.875em',
    letterSpacing: '-0.05px'
  },
  body1: {
    fontFamily: 'Rubik',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: palette.text.primary,
    fontSize: '1em !important',
    letterSpacing: '-0.05px'
  },
  body2: {
    color: palette.text.secondary,
    fontSize: '0.80em',
    letterSpacing: '-0.04px'
  },
  button: {
    color: palette.text.primary,
    fontSize: '0.825em !important'
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '11px',
    letterSpacing: '0.33px'
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.33px',
    textTransform: 'uppercase'
  }
}
