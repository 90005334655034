import { gql } from '@apollo/client'

export const INSERT_SESSION = gql`
  mutation (
    $committee_id: Int!
    $hour: String!
    $date: String!
    $type: String!
  ) {
    add_session(
      committee_id: $committee_id
      hour: $hour
      date: $date
      type: $type
    ) {
      id
    }
  }
`

export const EDIT_SESSION = gql`
  mutation (
    $id: Int!
    $prefix_code: String
    $suffix_code: String
    $consecutive: String
    $update: jsonb!
    $organizer: String!
  ) {
    edit_session(
      id: $id
      prefix_code: $prefix_code
      consecutive: $consecutive
      suffix_code: $suffix_code
      organizer: $organizer
      update: $update
    ) {
      success
    }
  }
`

export const MUTATION_UPDATE_STATE_SESSION = gql`
  mutation ($id: Int!, $state: String!) {
    update_session(where: { id: { _eq: $id } }, _set: { state: $state }) {
      affected_rows
    }
  }
`

export const GET_ALL_SESSION_BY_COMMITTEE = gql`
  query ($condition: session_view_bool_exp!, $limit: Int!, $offset: Int!) {
    session_view(
      where: $condition
      limit: $limit
      offset: $offset
      order_by: { date: desc }
    ) {
      id
      committee_id
      prefix_code
      suffix_code
      consecutive
      full_name
      date
      hour
      state
      active
      type
      committee {
        name
        code
      }
    }
    session_view_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
`

export const GET_ALL_SESSION_DATA = gql`
  mutation ($sessionId: Int!) {
    get_all_session_data(sessionId: $sessionId) {
      session
    }
  }
`

export const GET_MANY_SESSION_BY_STATE = gql`
  query ($committee_id: [Int!], $state: [String!], $active: Boolean!) {
    session(
      where: {
        committee_id: { _in: $committee_id }
        state: { _in: $state }
        active: { _eq: $active }
      }
      order_by: { id: asc }
    ) {
      id
      committee_id
      committee {
        id
      }
      consecutive
      prefix_code
      suffix_code
      date
      hour
      state
      type
    }
  }
`

export const SUBSCRIPTION_GET_ID = gql`
  subscription {
    session(order_by: { id: desc }) {
      id
      consecutive
      prefix_code
      suffix_code
      committee {
        id
        prefix_session_code
        consecutive_session_code
        suffix_session_code
      }
    }
  }
`

export const REMOVE_SESSION = gql`
  mutation ($sessions: [jsonb!], $organizer: String!) {
    remove_session(sessions: $sessions, organizer: $organizer) {
      success
    }
  }
`

export const FINISH_SESSION = gql`
  mutation ($session_id: Int!) {
    close_session(session_id: $session_id) {
      success
    }
  }
`
