import { gql } from '@apollo/client'

export const MUTATION_SINGIN = gql`
  mutation ($user: jsonb!) {
    signin(user: $user) {
      result
    }
  }
`

export const MUTATION_FORCE_CHANGE_PASS = gql`
  mutation ($user: jsonb!) {
    force_to_change_pass(user: $user) {
      result
    }
  }
`

export const SEND_CONFIRMATION_CODE = gql`
  mutation ($user: jsonb!) {
    send_confirmation_code(user: $user) {
      result
    }
  }
`

export const RESEND_TEMPORAL_PASS = gql`
  mutation ($user: jsonb!) {
    resend_temporal_pass(user: $user) {
      result
    }
  }
`

export const MUTATION_CHANGE_PASS = gql`
  mutation ($user: jsonb!) {
    change_pass(user: $user) {
      result
    }
  }
`

export const MUTATION_SINGUP = gql`
  mutation ($user: jsonb!) {
    signup(user: $user) {
      result
    }
  }
`

export const MUTATION_GET_USERS = gql`
  mutation ($currentUser: jsonb!) {
    get_users(currentUser: $currentUser) {
      result
    }
  }
`

export const MUTATION_REMOVE_USER = gql`
  mutation ($currentUser: jsonb!) {
    remove_user(currentUser: $currentUser) {
      result
    }
  }
`

export const MUTATION_SET_SIGNED_USERS = gql`
  mutation ($notificationMail: String!) {
    set_signed_user(notificationMail: $notificationMail) {
      success
    }
  }
`

export const MUTATION_EDIT_USER_PROFILE = gql`
  mutation ($user: jsonb!) {
    edit_user_profile(user: $user) {
      success
    }
  }
`

export const MUTATION_EDIT_USER = gql`
  mutation ($user: jsonb!) {
    edit_user(user: $user) {
      success
    }
  }
`

export const MUTATION_LOGOUT = gql`
  mutation {
    logout {
      success
    }
  }
`

export const MUTATION_UPDATE_PRIVATE_POLICY = gql`
  mutation ($email: String!) {
    edit_privacy_policy(email: $email) {
      success
    }
  }
`

export const INSERT_NEW_ROLE_BY_USER = gql`
  mutation ($roles: [jsonb]) {
    add_role_by_user(roles: $roles) {
      success
    }
  }
`

export const MUTATION_GET_TEMS_CONDITIONS = gql`
  mutation {
    termsConditions {
      result
    }
  }
`
