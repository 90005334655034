import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useLazyQuery, useMutation } from '@apollo/client'

import {
  UPDATE_NOTIFICATION_STATE,
  QUERY_GET_MANY_NOTIFICATION
} from '../../gql'
import { useUser } from '../../context/user.context'

const useNotificationHook = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const location = useLocation()
  const [currentUser] = useUser()
  const id = open ? 'simple-popover' : undefined
  const [notifications, setNotifications] = useState()
  const [getNotifications] = useLazyQuery(QUERY_GET_MANY_NOTIFICATION)
  const [changeNotificationState] = useMutation(UPDATE_NOTIFICATION_STATE)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLoadNotifications = useCallback(async () => {
    try {
      if (!currentUser) return

      const { data } = await getNotifications({
        variables: {
          user: currentUser.account
        },
        fetchPolicy: 'network-only'
      })

      setNotifications(data?.notification)
    } catch (error) {
      console.error('Error fetching notifications:', error)
    }
  }, [currentUser, getNotifications, setNotifications])

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseActions = async () => {
    await handleChangeNotificationState()

    handleClose()
  }

  const handleChangeNotificationState = async () => {
    const ids = notifications
      ?.filter(notification => !notification.seen)
      ?.map(notification => notification.id)

    if (ids?.length === 0) return

    await changeNotificationState({
      variables: {
        ids
      }
    })
  }

  const handleGoto = async notification => {
    await handleCloseActions()
    navigate(notification.route)
  }

  useEffect(() => {
    handleLoadNotifications()
  }, [
    handleLoadNotifications,
    location.pathname,
    getNotifications,
    setNotifications,
    open
  ])

  return [
    {
      notifications,
      anchorEl,
      open,
      id
    },
    {
      handleCloseActions,
      handleClick,
      handleGoto
    }
  ]
}

export default useNotificationHook
