const validatePrivilegesList = (validators, userPrivileges) => {
  if (validators?.length > 0) {
    for (let i = 0; i < validators?.length; i++) {
      if (
        userPrivileges?.some(privilege => privilege?.name === validators[i])
      ) {
        return true
      }
    }
    return false
  } else {
    return true
  }
}

const validatePrivileges = (validator, userPrivileges) => {
  for (let i = 0; i < userPrivileges?.length; i++) {
    if (userPrivileges[i]?.name === validator) {
      return true
    }
  }
  return false
}

export { validatePrivilegesList, validatePrivileges }
