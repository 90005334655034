import React, { Suspense, useState } from 'react'
import { Box, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { StylesProvider, createGenerateClassName } from '@mui/styles'

import theme from './theme'
import routes from './routes'
import Layout from './layouts/Layout'
import Loader from './components/Loader'

const generateClassName = createGenerateClassName({
  productionPrefix: 'actasProject'
})

const App = () => {
  const initialMenu = {
    principal: false,
    sesiones: false,
    agend: false,
    convocatory: false,
    transcript: false,
    minutes: false,
    agreements: false,
    organization: false,
    users: false,
    params: false
  }
  const [menuState, setMenuState] = useState(initialMenu)
  const [panelSelected, setPanelSelected] = useState('')

  const renderRoute = ({ component: Component, ...route }, index) => {
    if (Component) {
      return (
        <Route
          key={`path-${route.path}-${index}`}
          path={route.path}
          exact={route.exact}
          element={<Component />}
        />
      )
    } else {
      return route.submenu.map(
        ({ component: ComponentSubMenu, ...subMenuRoute }) => (
          <Route
            key={`path-${subMenuRoute.path}-${index}`}
            path={subMenuRoute.path}
            exact={subMenuRoute.exact}
            element={<ComponentSubMenu />}
          />
        )
      )
    }
  }

  const setMenu = selected => {
    setMenuState({ ...initialMenu, [selected]: !menuState[selected] })
  }

  return (
    <BrowserRouter>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Layout
            menuState={menuState}
            setMenuState={setMenu}
            panelSelected={panelSelected}
            setPanelSelected={setPanelSelected}
          >
            <Suspense
              fallback={
                <Box width='100%' height='100vh'>
                  <Loader />
                </Box>
              }
            >
              <Routes>{routes.map(renderRoute)}</Routes>
            </Suspense>
          </Layout>
        </ThemeProvider>
      </StylesProvider>
    </BrowserRouter>
  )
}

export default App
