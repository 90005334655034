import React from 'react'
import { Box, Typography } from '@mui/material'
import { useMediaQuery } from 'react-responsive'

import styles from './styles'

const logo = 'logos/logo.svg'

const Brand = ({ open }) => {
  const isTablet = useMediaQuery({ query: '(min-height: 900px)' })
  const style = styles()

  return (
    <Box
      className={isTablet ? style.brandBox : style.brandBoxMedium}
      alignItems={open ? 'center' : 'flex-start'}
    >
      <Typography
        color='white'
        variant='body1'
        className={open ? style.brandText : style.brandTextHide}
      >
        Desarrollado por
      </Typography>
      <img
        src={logo}
        alt='will logo'
        className={open ? style.brandLogo : style.brandLogoHide}
      />
      <Box display='flex' alignContent='center' mx={4}>
        <Typography
          color='white'
          variant='body2'
          className={open ? style.brandText : style.brandTextHide}
        >
          &copy; Todos los derechos reservados
        </Typography>
      </Box>
    </Box>
  )
}

export default Brand
