const uploadFile = async ({ signedS3Url, file }) => {
  try {
    const result = await fetch(signedS3Url, {
      method: 'PUT',
      body: file
    })

    return result.ok
  } catch (error) {
    console.log('Error aws front:', error)

    return null
  }
}

export { uploadFile }
