import { gql } from '@apollo/client'

export const MUTATION_INSERT_MINUTES = gql`
  mutation ($session_id: Int!, $committee_id: Int!, $file: jsonb!) {
    add_minutes(
      session_id: $session_id
      committee_id: $committee_id
      file: $file
    ) {
      success
    }
  }
`

export const MUTATION_GENERATE_MINUTES = gql`
  mutation ($session_id: Int!, $committee_id: Int!) {
    generate_minutes(session_id: $session_id, committee_id: $committee_id) {
      consecutive
      session_id
      code
      date
      hour
      topics
      participant_list
      attendance_list
      transcription_list
      minute_with_ia
      file
    }
  }
`

export const MUTATION_GENERATE_MINUTES_WITH_IA = gql`
  mutation (
    $sessionId: Int!
    $transcription: String!
    $date: String!
    $time: String!
    $sessionCode: String!
    $attendance: [String]!
  ) {
    generate_minutes_with_ia(
      sessionId: $sessionId
      transcription: $transcription
      date: $date
      time: $time
      sessionCode: $sessionCode
      attendance: $attendance
    ) {
      status
    }
  }
`

export const QUERY_GET_MANY_MINUTES = gql`
  query ($condition: minutes_view_bool_exp!, $limit: Int!, $offset: Int!) {
    minutes_view(
      where: $condition
      order_by: { session: { date: desc } }
      limit: $limit
      offset: $offset
    ) {
      id
      session_id
      committee_id
      prefix_minutes_code
      suffix_minutes_code
      consecutive
      file
      session {
        id
        prefix_code
        suffix_code
        state
        consecutive
        committee {
          contact
          id
          prefix_minutes_code
          suffix_minutes_code
          consecutive_minutes_code
          name
          organization_id
          prefix_session_code
          suffix_session_code
          consecutive_session_code
        }
        date
        committee_id
        hour
        active
      }
    }
    minutes_view_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
`

export const SUBSCRIPTION_COMMITTEE_GET_ID = gql`
  subscription {
    minutes(order_by: { id: desc }) {
      id
      prefix_minutes_code
      suffix_minutes_code
      consecutive
    }
  }
`
