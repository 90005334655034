import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  backgroundSideBar: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    display: 'flex',
    height: '100%'
  },
  accordion: {
    width: '100%',
    border: 'none',
    margin: '0',
    padding: '0',
    boxShadow: 'none'
  },
  accordionSummary: {
    backgroundColor: '#003399',
    '&:hover': {
      backgroundColor: '#FFB71B',
      transition: 'all .4s ease-in-out'
    }
  },
  accordionDetails: {
    backgroundColor: '#2A7DE1',
    display: 'grid',
    width: '100%'
  },
  listPadding: {
    marginBottom: 100,
    paddingTop: `0px !important`
  },
  removePadding: {
    padding: '0px !important'
  },
  subItems: {
    justifyContent: 'start',
    paddingLeft: '50px',
    color: '#ffffff',
    borderRadius: 0,
    borderBottom: '#003399',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    backgroundColor: '#2A7DE1',
    '&:hover': {
      backgroundColor: '#FFB71B',
      transition: 'all .4s ease-in-out'
    }
  },
  principalItem: {
    justifyContent: 'start',
    color: '#ffffff',
    borderRadius: 0,
    height: '50px',
    borderBottom: '#003399',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid'
  },
  externalButton: {
    width: '100%',
    height: '52px',
    display: 'flex',
    cursor: 'pointer',
    borderTop: '0.1px solid rgb(0,0,0,0.1)',
    '&:hover': {
      backgroundColor: '#FFB71B'
    }
  },
  iconDefault: {
    color: '#FFFFFF',
    opacity: '0.5'
  },
  iconImageDefault: {
    width: '26px'
  },
  itemSelected: {
    backgroundColor: '#FFB71B',
    '&:hover': {
      backgroundColor: '#FFB71B'
    }
  },
  sidebarIconStyle: {
    height: '100%',
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center'
  },
  brandText: {
    color: 'white',
    textAlign: 'center'
  },
  brandTextHide: {
    display: 'none'
  },
  brandLogo: {
    width: '90px',
    height: '80px',
    opacity: 0.5
  },
  brandLogoHide: {
    width: '45px',
    height: '40px',
    opacity: 0.5
  },
  brandBox: {
    width: '100%',
    height: '35vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center !important'
  },
  brandBoxMedium: {
    display: 'flex',
    height: '30vh',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  }
}))

export default useStyles
