import React from 'react'
import ReactDOM from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'

import './index.css'
import App from './App'
import { client } from './graphql'
import reportWebVitals from './reportWebVitals'
import { SharedStateProvider } from './context/user.context'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <SharedStateProvider>
    <ApolloProvider client={client}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ApolloProvider>
  </SharedStateProvider>
)

reportWebVitals()
