import React, { useState } from 'react'
import clsx from 'clsx'
import { Box, Grid } from '@mui/material'
import { useLocation } from 'react-router-dom'

import menu from '../routes/index'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'

import styles from './styles'

const Layout = ({
  children,
  menuState,
  setMenuState,
  panelSelected,
  setPanelSelected
}) => {
  const classes = styles()
  const location = useLocation()
  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const hideSidebarAndNavbar = () => {
    if (
      location.pathname === '/' ||
      location.pathname === '/terms-conditions' ||
      location.pathname === '/privacy-policy' ||
      location.pathname === '/change-password' ||
      location.pathname === '/reset-password' ||
      location.pathname === '/Atendance'
    )
      return false

    return true
  }

  return (
    <Box display='flex' bgcolor='#FFFFFF' width='100%'>
      <Grid container>
        {hideSidebarAndNavbar() && (
          <>
            <Navbar
              setPanelSelected={setPanelSelected}
              handleDrawerOpen={handleDrawerOpen}
              classes={classes}
              open={open}
            />
            <Sidebar
              handleDrawerClose={handleDrawerClose}
              setPanelSelected={setPanelSelected}
              panelSelected={panelSelected}
              setMenuState={setMenuState}
              menuState={menuState}
              setOpen={setOpen}
              classes={classes}
              open={open}
              menu={menu}
            />
          </>
        )}
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open
          })}
        >
          {children}
        </main>
      </Grid>
    </Box>
  )
}

export default Layout
