import { colors } from '@mui/material'

export default {
  primary: {
    main: '#003399',
    light: '#ECF2FF'
  },
  secondary: {
    main: '#FF0000',
    light: '#FFB71B'
  },
  grey: {
    300: '#DADDDE',
    800: '#4c4d4e'
  },
  info: {
    main: '#2A7DE1'
  },
  text: {
    primary: '#5B6670',
    secondary: '#43425D',
    white: '#FFFFFF',
    blue: '#003399',
    link: colors.blue[600]
  }
}
