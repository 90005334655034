import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  centerBox: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto'
  },
  verticalCenter: {
    margin: 'auto',
    paddingTop: '23px',
    paddingLeft: '23px'
  },
  containerGrid: {
    height: '100%'
  },
  gridStyle: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  menuButtonContainer: {
    height: '100%',
    width: '15%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  logoContainerStyle: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: theme.palette.common.white
  },
  logoImageStyle: {
    width: '60px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  nameContainerStyle: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  nameStyle: {
    color: '#FFFFFF',
    fontWeight: 'bold'
  },
  notificationContainerStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    width: '20%'
  },
  userButtonContainerStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    width: '60%'
  },
  userButtonStyle: {
    backgroundColor: 'transparent !important',
    color: '#FFB71B !important',
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  organizationButtonStyle: {
    background: 'transparent !important',
    fontWeight: 'bold !important',
    color: 'white',
    '&:hover': {
      background: 'transparent !important'
    }
  },
  disabledButton: {
    background: 'green',
    color: 'white',
    fontWeight: 'bold'
  },
  userImageContainerStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  userImageStyle: {
    backgroundColor: theme.palette.common.white,
    objectFit: 'contain',
    width: '45px',
    border: '3px solid #FFB71B',
    borderRadius: '50px',
    height: '45px'
  }
}))

export default useStyles
