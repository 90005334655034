import { gql } from '@apollo/client'

export const GET_SIGNED_S3_URL = gql`
  mutation (
    $fileName: String!
    $organization: String!
    $committee: Int
    $session: Int
    $consecutive: Int
    $actionType: String!
    $fileType: String!
    $operation: String!
  ) {
    get_signed_s3_url(
      fileName: $fileName
      organization: $organization
      committee: $committee
      session: $session
      consecutive: $consecutive
      actionType: $actionType
      fileType: $fileType
      operation: $operation
    ) {
      url
    }
  }
`
