const uploadFileNameFormat = fileName => {
  const formattedFileName = fileName
    .replace(/\s+/g, '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  return formattedFileName
}

export { uploadFileNameFormat }
