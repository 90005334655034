import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useNavigate, useLocation } from 'react-router-dom'

import {
  MUTATION_LOGOUT,
  GET_SIGNED_S3_URL,
  GET_ORGANIZATIONS_BY_ID
} from '../../gql'
import { useUser } from '../../context/user.context'

const useNavbarHook = () => {
  const navigate = useNavigate()
  const locationa = useLocation()
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentUser, { logout, updateOrg }] = useUser()
  const [userCurrentPhoto, setUserCurrentPhoto] = useState()
  const isTablet = useMediaQuery({ query: '(min-width: 600px)' })
  const [organizationCurrentImage, setOrganizationCurrentImage] =
    useState(undefined)
  const [organizationsMenu, setOrganizationsMenu] = useState(null)
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })

  const [backendLogout] = useMutation(MUTATION_LOGOUT)
  const [getSignedS3Url] = useMutation(GET_SIGNED_S3_URL)
  const [getOrganizations] = useLazyQuery(GET_ORGANIZATIONS_BY_ID)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const showOrganizationsMenu = event => {
    setOrganizationsMenu(event.currentTarget)
  }

  const hideOrganizationsMenu = () => {
    setOrganizationsMenu(null)
  }

  const onPressLogOut = async () => {
    logout()
    navigate('/')
    await backendLogout()
  }

  const onPressOrganization = async organizationId => {
    const { data: organizations } = await getOrganizations({
      variables: {
        organizations: organizationId
      }
    })

    updateOrg(JSON.stringify(organizations.organization[0]))
    setOrganizationsMenu(null)
    location.replace(`/${locationa?.pathname?.split('/')[1]}`)
  }

  useEffect(() => {
    const getUserPhoto = async () => {
      try {
        if (currentUser?.organization?.logo?.Key) {
          const {
            data: {
              get_signed_s3_url: { url: signedS3Url }
            }
          } = await getSignedS3Url({
            variables: {
              fileName: currentUser?.organization?.logo?.Key?.split('/')[2],
              organization: currentUser?.organization?.id,
              actionType: 'images',
              fileType: currentUser?.organization?.logo?.Key?.split('/')[2]
                ?.split('.')
                ?.at(-1),
              operation: 'getObject'
            }
          })
          setOrganizationCurrentImage(signedS3Url)
        }

        if (currentUser?.photo === 'undefined' || currentUser?.photo === '/')
          return

        const {
          data: {
            get_signed_s3_url: { url: signedS3UrlGet }
          }
        } = await getSignedS3Url({
          variables: {
            fileName: currentUser?.photo?.split('/')[3],
            organization: `${currentUser?.organization?.id}/${currentUser?.account}`,
            actionType: 'images',
            fileType: currentUser?.photo?.split('/')[3]?.split('.')?.at(-1),
            operation: 'getObject'
          }
        })

        setUserCurrentPhoto(signedS3UrlGet)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    if (!currentUser) return

    getUserPhoto()
  }, [currentUser])

  return [
    {
      anchorEl,
      isTablet,
      isDesktop,
      currentUser,
      userCurrentPhoto,
      organizationsMenu,
      organizationCurrentImage
    },
    {
      navigate,
      handleClick,
      handleClose,
      onPressLogOut,
      onPressOrganization,
      setUserCurrentPhoto,
      showOrganizationsMenu,
      hideOrganizationsMenu
    }
  ]
}

export default useNavbarHook
