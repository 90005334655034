export * from './fast-transcription.gql'
export * from './transcription.gql'
export * from './notification.gql'
export * from './announcement.gql'
export * from './organization.gql'
export * from './committee.gql'
export * from './agreement.gql'
export * from './savefiles.gql'
export * from './session.gql'
export * from './minutes.gql'
export * from './diary.gql'
export * from './roles.gql'
export * from './user.gql'
