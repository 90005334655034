import { gql } from '@apollo/client'

export const GET_ALL_ORGANIZATION = gql`
  query {
    organization {
      contact
      id
      name
    }
  }
`

export const GET_ALL_COMMITTEES = gql`
  query {
    committee {
      id
      name
      code
      contact
      organization_id
      suffix_session_code
      prefix_session_code
      consecutive_session_code
      prefix_minutes_code
      suffix_minutes_code
      consecutive_minutes_code
    }
  }
`

export const GET_ORGANIZATIONS_BY_ID = gql`
  query ($organizations: [String!]) {
    organization(where: { id: { _in: $organizations } }) {
      id
      name
      logo
      contact
      transcription_method
      total_minutes_transcribed
      maximum_transcription_time
    }
  }
`

export const UPDATE_ORGANIZATION = gql`
  mutation (
    $name: String!
    $contact: jsonb!
    $id: String!
    $total_minutes_transcribed: numeric
    $transcription_method: Int
    $logo: jsonb
  ) {
    update_organization(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        contact: $contact
        transcription_method: $transcription_method
        total_minutes_transcribed: $total_minutes_transcribed
        logo: $logo
      }
    ) {
      affected_rows
    }
  }
`

export const INSERT_ORGANIZATION = gql`
  mutation (
    $name: String!
    $contact: jsonb!
    $id: String!
    $maximumTranscriptionTime: Int!
    $logo: jsonb
    $transcription_method: Int
  ) {
    insert_organization(
      objects: {
        id: $id
        contact: $contact
        transcription_method: $transcription_method
        logo: $logo
        maximum_transcription_time: $maximumTranscriptionTime
        name: $name
      }
    ) {
      returning {
        id
      }
    }
  }
`
