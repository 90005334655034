import { gql } from '@apollo/client'

export const MUTATION_INSERT_DIARY = gql`
  mutation ($session_id: Int!, $committee_id: Int!, $topics: [jsonb]) {
    add_diary(
      session_id: $session_id
      committee_id: $committee_id
      topics: $topics
    ) {
      success
    }
  }
`

export const GET_ALL_DIARY = gql`
  query {
    diary {
      id
      organization_id
      date
      hour
      state
      type
    }
  }
`

export const GET_MANY_DIARY = gql`
  query ($condition: diary_view_bool_exp!, $limit: Int!, $offset: Int!) {
    diary_view(
      where: $condition
      order_by: { session: { date: desc } }
      limit: $limit
      offset: $offset
    ) {
      id
      session_id
      committee_id
      topics
      state
      session {
        prefix_code
        suffix_code
        consecutive
        state
      }
    }
    diary_view_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
`

export const REMOVE_DIARY = gql`
  mutation ($diaries: [Int!]) {
    remove_diary(diaries: $diaries) {
      success
    }
  }
`

export const EDIT_DIARY = gql`
  mutation (
    $id: Int!
    $update: jsonb
    $sort: jsonb
    $remove: jsonb
    $new: jsonb
    $organizer: String
  ) {
    edit_diary(
      id: $id
      update: $update
      sort: $sort
      remove: $remove
      new: $new
      organizer: $organizer
    ) {
      success
    }
  }
`
