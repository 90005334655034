const CREDENTIAL_KEYS = {
  username: 'EMAIL',
  password: 'PASSWORD'
}

const setEmailLoginCredentials = async user => {
  try {
    await localStorage.setItem(CREDENTIAL_KEYS.username, user.username)
    await localStorage.setItem(CREDENTIAL_KEYS.password, user.password)
  } catch (err) {
    console.log(err)
  }
}

const getEmailLoginCredentials = async () => {
  try {
    const username = await localStorage.getItem(CREDENTIAL_KEYS.username)
    const password = await localStorage.getItem(CREDENTIAL_KEYS.password)
    return { user: { username, password } }
  } catch (err) {
    console.log(err)
  }
}

const removeEmailLoginCredentials = async () => {
  try {
    await localStorage.removeItem(CREDENTIAL_KEYS.username)
    await localStorage.removeItem(CREDENTIAL_KEYS.password)
  } catch (err) {
    console.log(err)
  }
}

export {
  setEmailLoginCredentials,
  getEmailLoginCredentials,
  removeEmailLoginCredentials
}
