const campaignIcon = '/icons/campaignIcon.svg'
const acuerdosIcon = '/icons/acuerdosIcon.svg'
const transcribeIcon = '/icons/transcribeIcon.svg'
const actasIcon = '/icons/actasIcon.svg'
import BusinessIcon from '@material-ui/icons/Business'
import ForumIcon from '@material-ui/icons/Forum'
import EventIcon from '@material-ui/icons/Event'
import SettingsIcon from '@material-ui/icons/Settings'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import AppsIcon from '@material-ui/icons/Apps'
import ReceiptIcon from '@material-ui/icons/Receipt'
import MailIcon from '@material-ui/icons/Mail'

import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined'
import GraphicEqOutlinedIcon from '@material-ui/icons/GraphicEqOutlined'
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined'

const getIcon = (iconName, iconStyle, sidebarStyles) => {
  switch (iconName) {
    case 'ForumIcon':
      return <ForumIcon className={iconStyle} />
    case 'EventIcon':
      return <EventIcon className={iconStyle} />
    case 'SettingsIcon':
      return <SettingsIcon className={iconStyle} />
    case 'SupervisorAccountIcon':
      return <SupervisorAccountIcon className={iconStyle} />
    case 'AppsIcon':
      return <AppsIcon className={iconStyle} />
    case 'ReceiptIcon':
      return <ReceiptIcon className={iconStyle} />
    case 'transcribeIcon':
      return <GraphicEqOutlinedIcon className={iconStyle} />
    case 'campaignIcon':
      return <RecordVoiceOverOutlinedIcon className={iconStyle} />
    case 'acuerdosIcon':
      return <AssignmentOutlinedIcon className={iconStyle} />
    case 'actasIcon':
      return <MenuBookOutlinedIcon className={iconStyle} />
    case 'BusinessIcon':
      return <BusinessIcon className={iconStyle} />
    default:
      return <MailIcon className={sidebarStyles.iconDefault} />
  }
}

export default getIcon
