import { gql } from '@apollo/client'

export const MUTATION_INSERT_FAST_TRANSCRIPTION = gql`
  mutation (
    $method: Int!
    $committee_id: Int!
    $duration: numeric!
    $organizationID: String!
    $transcription_list: [jsonb]
  ) {
    add_fast_transcription(
      method: $method
      duration: $duration
      committee_id: $committee_id
      organizationID: $organizationID
      transcription_list: $transcription_list
    ) {
      state
    }
  }
`

export const MUTATION_UPDATE_FAST_TRANSCRIPTION_TRANSCRIPTION_LIST = gql`
  mutation ($committee_id: Int!, $transcription_list: jsonb) {
    update_fast_transcription(
      where: { committee_id: { _eq: $committee_id } }
      _set: { transcription_list: $transcription_list }
    ) {
      affected_rows
    }
  }
`

export const QUERY_GET_MANY_FAST_TRANSCRIPTION = gql`
  query ($committee_id: Int!) {
    fast_transcription(where: { committee_id: { _eq: $committee_id } }) {
      id
      committee_id
      transcription_list
    }
  }
`

export const EDIT_FAST_TRANSCRIPTION = gql`
  mutation ($update: jsonb!) {
    edit_fast_transcription(update: $update) {
      success
    }
  }
`
