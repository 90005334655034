import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    minHeight: '100%',
    padding: '100px'
  },
  loader: {
    color: '#003399'
  }
}))

export default useStyles
