import { makeStyles } from '@mui/styles'

const drawerWidth = 280
const shortWidth = 55

const useStyles = makeStyles(theme => ({
  signUpBackground: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    backgroundColor: '#DADDDE !important'
  },
  bodyBox: {
    width: '80%',
    float: 'right',
    height: 'calc(100% - 90px)',
    overflow: 'auto'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    top: 0,
    height: '70px',
    padding: theme.spacing(0, 2),
    backgroundColor: '#021334 !important'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    width: '30%'
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerShort: {
    width: shortWidth,
    flexShrink: 0
  },
  drawerPaper: {
    marginTop: 70,
    width: drawerWidth,
    backgroundColor: `${theme.palette.primary.main} !important`
  },
  drawerPaperShort: {
    width: 0,
    marginTop: 70,
    backgroundColor: `${theme.palette.primary.main} !important`,
    '@media (min-width: 600px)': {
      width: shortWidth
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 85,
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    '@media (max-width: 600px)': {
      width: '100%'
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  contentShift: {
    width: '70%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  mainItem: {
    height: 50,
    paddingLeft: '0px !important',
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    borderBottom: 'solid',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFB71B !important',
      '& $mainItemIcon': {
        color: `${theme.palette.text.blue} !important`,
        opacity: '1'
      },
      '& $mainItemText': {
        color: `${theme.palette.text.blue} !important`
      },
      '& $mainItemExpandIcon': {
        color: `${theme.palette.text.blue} !important`
      }
    }
  },
  mainItemActive: {
    height: 50,
    paddingLeft: 0,
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: '#FFB71B',
      '& $mainItemIconActive': {
        color: `${theme.palette.text.blue} !important`
      },
      '& $mainItemText': {
        color: `${theme.palette.text.blue} !important`
      },
      '& $mainItemExpandIcon': {
        color: `${theme.palette.text.blue} !important`
      }
    }
  },
  mainItemActiveSelected: {
    height: 50,
    paddingLeft: '0px !important',
    backgroundColor: '#FFB71B !important',
    '&:hover': {
      backgroundColor: '#FFB71B !important',
      '& $mainItemIconActive': {
        color: `${theme.palette.text.blue} !important`
      },
      '& $mainItemText': {
        color: `${theme.palette.text.blue} !important`
      },
      '& $mainItemExpandIcon': {
        color: `${theme.palette.text.blue} !important`
      }
    }
  },
  mainItemIcon: {
    color: '#FFFFFF',
    opacity: '0.5'
  },
  mainItemIconActive: {
    color: '#FFFFFF'
  },
  mainItemIconActiveSelected: {
    color: `${theme.palette.text.blue} !important`
  },
  mainItemText: {
    height: 50,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: `${theme.palette.text.white} !important`
  },
  mainItemTextSelected: {
    height: 50,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: `${theme.palette.text.blue} !important`
  },
  mainItemExpandIcon: {
    color: theme.palette.text.white
  },
  nested: {
    cursor: 'pointer',
    backgroundColor: '#2A7DE1',
    paddingLeft: `${theme.spacing(9)} !important`,
    '&:hover': {
      backgroundColor: '#FFB71B',
      '& $subMenuItemText': {
        color: `${theme.palette.text.blue} !important`
      }
    }
  },
  subMenuItem: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    borderBottom: 'solid'
  },
  subMenuItemSelected: {
    paddingLeft: `${theme.spacing(9)} !important`,
    backgroundColor: '#FFB71B',
    '&:hover': {
      backgroundColor: '#FFB71B'
    }
  },
  subMenuItemText: {
    color: `${theme.palette.text.white} !important`
  },
  subMenuItemTextSelected: {
    color: `${theme.palette.text.blue} !important`
  }
}))

export default useStyles
