const sessionStateUtil = {
  new: 'Nuevo',
  scheduling: 'Agendando',
  summoning: 'Convocando',
  transcribing: 'Transcribiendo',
  transcribed: 'Transcrita',
  finishing: 'Finalizando',
  closed: 'Cerrado'
}

const transcriptionMethods = [
  { id: 0, label: 'Método AWS' },
  { id: 1, label: 'Método Open AI' },
  { id: 2, label: 'Método Deep Gram' }
]

export { sessionStateUtil, transcriptionMethods }
