import React from 'react'
import { Box } from '@mui/material'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'

import styles from './styles'
import useNotificationHook from './useNotificationHook'
import NotificationContainer from './NotificationContainer'

const Notification = () => {
  const classes = styles()
  const [
    { notifications, anchorEl, open, id },
    { handleCloseActions, handleClick, handleGoto }
  ] = useNotificationHook()

  return (
    <div>
      <Box onClick={handleClick} position='relative'>
        {notifications?.filter(notification => !notification.seen)?.length >
          0 && (
          <Box
            position='absolute'
            borderRadius='50%'
            bgcolor='#FFB71B'
            height={10}
            width={10}
            right={0}
          />
        )}
        <NotificationsActiveOutlinedIcon
          className={classes.notificationIconStyle}
        />
      </Box>
      {notifications?.length ? (
        <NotificationContainer
          handleCloseActions={handleCloseActions}
          notifications={notifications}
          handleGoto={handleGoto}
          anchorEl={anchorEl}
          open={open}
          id={id}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default Notification
