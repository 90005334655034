const downloadFile = async ({ signedS3Url }) => {
  try {
    const result = await fetch(signedS3Url, {
      method: 'GET'
    })

    if (result.ok) {
      window.open(signedS3Url, '_blank')

      return true
    }

    return false
  } catch (error) {
    console.log('Error aws front:', error)
    return null
  }
}

export { downloadFile }
