import { createTheme, responsiveFontSizes } from '@mui/material/styles'

import breakpoints from './breakpoints'
import typography from './typography'
import palette from './palette'

const theme = responsiveFontSizes(
  createTheme({
    breakpoints,
    typography,
    palette
  })
)

export default theme
