import { gql } from '@apollo/client'

export const GET_ALL_ROLES_SA = gql`
  query ($organization_id: String!, $limit: Int!, $offset: Int!) {
    role(
      where: {
        _or: [
          { organization_id: { _eq: $organization_id } }
          { organization_id: { _is_null: true } }
        ]
      }
      order_by: { id: asc }
      limit: $limit
      offset: $offset
    ) {
      id
      name
      superadmin
      deletable
      privileges_by_roles {
        privilege {
          id
          name
        }
      }
    }
    role_aggregate {
      aggregate {
        count
      }
    }
  }
`

export const GET_ALL_ROLES = gql`
  query ($organization_id: String!, $limit: Int!, $offset: Int!) {
    role(
      where: {
        _or: [
          { organization_id: { _eq: $organization_id } }
          { organization_id: { _is_null: true } }
        ]
        _and: { superadmin: { _eq: false } }
      }
      order_by: { id: asc }
      limit: $limit
      offset: $offset
    ) {
      id
      name
      superadmin
      deletable
      privileges_by_roles {
        privilege {
          id
          name
        }
      }
    }
    role_aggregate {
      aggregate {
        count
      }
    }
  }
`
export const GET_ONE_ROLE = gql`
  query ($id: Int!) {
    role(where: { id: { _eq: $id } }) {
      id
      name
      privileges_by_roles {
        privilege {
          id
          name
        }
      }
    }
  }
`

export const GET_ALL_PRIVILEGES = gql`
  query {
    privilege {
      id
      name
    }
  }
`

export const INSERT_ROLE = gql`
  mutation ($name: String!, $organization_id: String!, $privileges: [jsonb]) {
    add_role(
      name: $name
      organization_id: $organization_id
      privileges: $privileges
    ) {
      success
    }
  }
`

export const REMOVE_ROLE = gql`
  mutation ($id: Int!) {
    remove_role(id: $id) {
      success
    }
  }
`
