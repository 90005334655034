import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  notificationIconStyle: {
    color: theme.palette.common.withe,
    cursor: 'pointer',
    width: '30px',
    height: '30px'
  },
  notifyItem: {
    cursor: 'pointer',
    transition: 'background-color 0.4s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 183, 27, 0.4) !important'
    }
  }
}))

export default useStyles
