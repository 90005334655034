import { gql } from '@apollo/client'

export const MUTATION_INSERT_AGREEMENT = gql`
  mutation addAgreement($agreement: AgreementInput!) {
    add_agreement(agreement: $agreement) {
      success
    }
  }
`

export const QUERY_GET_ALL_AGREEMENT = gql`
  query ($condition: agreement_view_bool_exp!, $limit: Int!, $offset: Int!) {
    agreement_view(where: $condition, limit: $limit, offset: $offset) {
      id
      type
      state
      title
      history
      date_end
      date_start
      session_id
      description
      responsible
      committee_id
    }
    agreement_view_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
`

export const REMOVE_AGREEMENT = gql`
  mutation ($agreements: [jsonb!]) {
    remove_agreement(agreements: $agreements) {
      success
    }
  }
`

export const DISABLE_AGREEMENT = gql`
  mutation ($agreements: [jsonb!]) {
    disable_agreement(agreements: $agreements) {
      success
    }
  }
`

export const EDIT_AGREEMENT = gql`
  mutation ($id: Int!, $update: jsonb!, $organizer: String!) {
    edit_agreement(id: $id, update: $update, organizer: $organizer) {
      success
    }
  }
`
