import { gql } from '@apollo/client'

export const QUERY_GET_MANY_NOTIFICATION = gql`
  query ($user: String!) {
    notification(where: { user: { _eq: $user } }) {
      id
      seen
      date
      user
      route
      title
      description
    }
  }
`

export const UPDATE_NOTIFICATION_STATE = gql`
  mutation ($ids: [uuid!]) {
    update_notification(_set: { seen: true }, where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`
