import React from 'react'
import jwtDecode from 'jwt-decode'
import { isObject } from '../utils'

const SharedStateContext = React.createContext()

const getUserFromToken = (
  token,
  photo,
  accessToken,
  privileges,
  organization,
  organizations,
  privatePolicyAccepted
) => {
  const claims = jwtDecode(token)

  return {
    account: claims.email,
    organization: JSON.parse(organization),
    organizations: JSON.parse(organizations),
    committee: JSON.parse(claims['custom:committeeList']),
    role: JSON.parse(claims['custom:rolesList']),
    accessToken,
    photo,
    expToken: claims.exp,
    privileges: privileges,
    privatePolicyAccepted
  }
}

const userReducer = (state, action) => {
  switch (action.type) {
    case 'login': {
      const claims = jwtDecode(action.payload.token)
      localStorage.setItem('token', action.payload.token)
      localStorage.setItem('photo', claims['custom:photo'])
      localStorage.setItem('privileges', JSON.stringify(action.privileges))
      localStorage.setItem('accessToken', action.payload.accessToken)
      localStorage.setItem('organization', action.organization)
      localStorage.setItem('organizations', action.organizations)
      localStorage.setItem(
        'privatePolicyAccepted',
        action.privatePolicyAccepted
      )
      const loginData = {
        ...(state || {}),
        ...getUserFromToken(
          action.payload.token,
          localStorage.getItem('photo'),
          action.payload.accessToken,
          action.privileges,
          action.organization,
          action.organizations,
          action.privatePolicyAccepted
        )
      }

      if (!!action.committee) {
        loginData.committee = action.committee
      }

      return loginData
    }
    case 'updateOrg': {
      localStorage.setItem('organization', action.organization)
      return state
    }
    case 'set': {
      localStorage.setItem(
        action?.payload?.param,
        action?.payload[action?.payload?.param]
      )

      return {
        ...state,
        ...{
          [action?.payload?.param]: isObject(
            action?.payload[action?.payload?.param]
          )
            ? JSON.parse(action?.payload[action?.payload?.param])
            : action?.payload[action?.payload?.param]
        }
      }
    }
    case 'logout': {
      localStorage.removeItem('token')

      return null
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`)
    }
  }
}

export const useUser = () => {
  const context = React.useContext(SharedStateContext)

  if (!context) {
    throw new Error(`useUser must be used within a SharedStateContextProvider`)
  }

  const [user, dispatch] = context
  const login = (
    token,
    accessToken,
    committee,
    privileges,
    organization,
    organizations,
    privatePolicyAccepted
  ) =>
    dispatch({
      type: 'login',
      payload: { token, accessToken },
      committee,
      privileges,
      organization,
      organizations,
      privatePolicyAccepted
    })
  const updateOrg = organization =>
    dispatch({ type: 'updateOrg', organization })
  const setState = payload => dispatch({ type: 'set', payload })
  const logout = () => dispatch({ type: 'logout' })

  return [
    user,
    {
      setState,
      login,
      logout,
      updateOrg
    }
  ]
}

export const SharedStateProvider = props => {
  const initialValue = localStorage.getItem('token')
    ? getUserFromToken(
        localStorage.getItem('token'),
        localStorage.getItem('photo'),
        localStorage.getItem('accessToken'),
        JSON.parse(localStorage.getItem('privileges')),
        localStorage.getItem('organization'),
        localStorage.getItem('organizations'),
        localStorage.getItem('privatePolicyAccepted')
      )
    : {}
  const [state, dispatch] = React.useReducer(userReducer, initialValue)
  const value = React.useMemo(() => [state, dispatch], [state])

  return <SharedStateContext.Provider value={value} {...props} />
}
