import ExportJsonExcel from 'js-export-excel'

export const exportToExcel = ({ sheetData, fileName }) => {
  const option = {}

  option.fileName = fileName
  option.datas = [
    {
      sheetData,
      sheetName: 'Datos'
    }
  ]

  const toExcel = new ExportJsonExcel(option)

  toExcel.saveExcel()
}
