import { gql } from '@apollo/client'

export const MUTATION_INSERT_ANNOUNCEMENT = gql`
  mutation (
    $session_id: Int!
    $committee_id: Int!
    $date: String!
    $hour: String!
    $participant_list: [String!]
    $organizer: String!
  ) {
    add_announcement(
      session_id: $session_id
      committee_id: $committee_id
      date: $date
      hour: $hour
      participant_list: $participant_list
      organizer: $organizer
    ) {
      success
    }
  }
`

export const QUERY_GET_ALL_ANNOUNCEMENT = gql`
  query ($condition: announcement_view_bool_exp!, $limit: Int!, $offset: Int!) {
    announcement_view(
      where: $condition
      limit: $limit
      offset: $offset
      order_by: { session: { date: desc } }
    ) {
      id
      date
      hour
      full_name
      committee_id
      participant_list
      attendance_list
      session_id
      session {
        prefix_code
        suffix_code
        consecutive
        state
      }
      diary {
        topics
      }
    }
    announcement_view_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
`

export const REMOVE_ANNOUNCEMENT = gql`
  mutation ($announcements: [Int!]) {
    remove_announcement(announcements: $announcements) {
      success
    }
  }
`

export const EDIT_ANNOUNCEMENT = gql`
  mutation ($id: Int!, $update: jsonb!, $organizer: String!) {
    edit_announcement(id: $id, update: $update, organizer: $organizer) {
      success
    }
  }
`
