import { gql } from '@apollo/client'

export const INSERT_COMMITTEE = gql`
  mutation ($committee: committee_insert_input!) {
    insert_committee_one(object: $committee) {
      code
      name
      contact
      prefix_minutes_code
      consecutive_minutes_code
      prefix_session_code
      consecutive_session_code
      organization_id
    }
  }
`

export const UPDATE_COMMITTEE = gql`
  mutation (
    $code: String!
    $prefix_minutes_code: String
    $suffix_minutes_code: String
    $consecutive_minutes_code: String
    $prefix_session_code: String
    $consecutive_session_code: String
    $suffix_session_code: String
    $id: Int!
    $name: String!
  ) {
    update_committee(
      where: { id: { _eq: $id } }
      _set: {
        prefix_minutes_code: $prefix_minutes_code
        suffix_minutes_code: $suffix_minutes_code
        consecutive_minutes_code: $consecutive_minutes_code
        prefix_session_code: $prefix_session_code
        consecutive_session_code: $consecutive_session_code
        suffix_session_code: $suffix_session_code
        code: $code
        name: $name
      }
    ) {
      affected_rows
    }
  }
`

export const UPDATE_COMMITTEE_START_VALUES = gql`
  mutation ($code: String!, $id: Int!, $name: String!) {
    update_committee(
      where: { id: { _eq: $id } }
      _set: { code: $code, name: $name }
    ) {
      affected_rows
    }
  }
`

export const GET_ALL_COMMITTEE_BY_ORGANIZATION = gql`
  query ($organizations: [String]!) {
    committee(where: { organization_id: { _in: $organizations } }) {
      id
      code
      name
      prefix_minutes_code
      suffix_minutes_code
      consecutive_minutes_code
      organization_id
      prefix_session_code
      suffix_session_code
      consecutive_session_code
    }
  }
`

export const GET_ONE_COMMITTEE = gql`
  query ($committee_id: Int!) {
    committee(where: { id: { _eq: $committee_id } }) {
      prefix_minutes_code
      suffix_minutes_code
      consecutive_minutes_code
      prefix_session_code
      suffix_session_code
      consecutive_session_code
      organization_id
      name
      id
      contact
    }
  }
`

export const GET_COMMITTEES = gql`
  query ($committees_id: [Int]!) {
    committee(where: { id: { _in: $committees_id } }) {
      prefix_minutes_code
      suffix_minutes_code
      consecutive_minutes_code
      prefix_session_code
      suffix_session_code
      consecutive_session_code
      organization_id
      name
      id
      contact
    }
  }
`

export const REMOVE_COMMITTEE = gql`
  mutation ($committeeIds: [Int!]) {
    remove_committee(committeeIds: $committeeIds) {
      success
    }
  }
`
