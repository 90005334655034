import { gql } from '@apollo/client'

export const MUTATION_INSERT_TRANSCRIPTION = gql`
  mutation (
    $session_id: Int!
    $committee_id: Int!
    $transcription_list: [jsonb]
  ) {
    add_transcription(
      session_id: $session_id
      committee_id: $committee_id
      transcription_list: $transcription_list
    ) {
      id
    }
  }
`

export const MUTATION_TRANSCRIBE_FILE = gql`
  mutation (
    $nameTranscription: String!
    $formatFile: String!
    $fileName: String!
    $session_id: Int!
    $duration: numeric!
    $organizationID: String!
  ) {
    transcribe(
      nameTranscription: $nameTranscription
      formatFile: $formatFile
      fileName: $fileName
      session_id: $session_id
      duration: $duration
      organizationID: $organizationID
    ) {
      state
    }
  }
`

export const MUTATION_OPENAI_TRANSCRIBE_FILE = gql`
  mutation (
    $Key: String
    $session_id: Int!
    $duration: numeric
    $organization: String!
  ) {
    openAiTranscribe(
      Key: $Key
      duration: $duration
      session_id: $session_id
      organization: $organization
    ) {
      state
    }
  }
`

export const MUTATION_DEEP_GRAM_TRANSCRIBE_FILE = gql`
  mutation (
    $Key: String
    $session_id: Int!
    $duration: numeric
    $organization: String!
  ) {
    deepGramTranscribe(
      Key: $Key
      duration: $duration
      session_id: $session_id
      organization: $organization
    ) {
      state
    }
  }
`


export const MUTATION_UPDATE_TRANSCRIPTION_TRANSCRIPTION_LIST = gql`
  mutation ($session_id: Int!, $transcription_list: jsonb) {
    update_transcription(
      where: { session_id: { _eq: $session_id } }
      _set: { transcription_list: $transcription_list }
    ) {
      affected_rows
    }
  }
`

export const GET_ALL_TRANSCRIPTION = gql`
  query {
    transcription {
      id
      committee_id
      date
      hour
      state
      type
    }
  }
`

export const QUERY_GET_ONE_TRANSCRIPTION = gql`
  query ($session_id: Int!, $committee_id: Int!) {
    transcription(
      limit: 1
      where: {
        session_id: { _eq: $session_id }
        committee_id: { _eq: $committee_id }
      }
    ) {
      id
      session_id
      committee_id
      transcription_list
    }
  }
`

export const QUERY_GET_MANY_TRANSCRIPTION = gql`
  query ($committee_id: Int!) {
    transcription(where: { committee_id: { _eq: $committee_id } }) {
      id
      session_id
      committee_id
      transcription_list
    }
  }
`

export const REMOVE_TRANSCRIPTION = gql`
  mutation ($id: Int!) {
    remove_transcription(id: $id) {
      success
    }
  }
`

export const EDIT_TRANSCRIPTION = gql`
  mutation ($id: Int!, $update: jsonb!) {
    edit_transcription(id: $id, update: $update) {
      success
    }
  }
`
