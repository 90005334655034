import React from 'react'
import {
  Box,
  Grid,
  Menu,
  Button,
  AppBar,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import { withStyles } from '@mui/styles'
import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import BusinessIcon from '@mui/icons-material/Business'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'

import Notification from '../Notification'

import styles from './styles'
import useNavbarHook from './useNavbarHook'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))

const Navbar = ({ handleDrawerOpen, classes }) => {
  const navbarStyles = styles()
  const [
    {
      anchorEl,
      isTablet,
      isDesktop,
      currentUser,
      userCurrentPhoto,
      organizationsMenu,
      organizationCurrentImage
    },
    {
      navigate,
      handleClick,
      handleClose,
      onPressLogOut,
      onPressOrganization,
      setUserCurrentPhoto,
      showOrganizationsMenu,
      hideOrganizationsMenu
    }
  ] = useNavbarHook()

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Grid container className={navbarStyles.containerGrid}>
        <Grid item xs={11} sm={6} md={4}>
          <Box
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='start'
          >
            <Box className={navbarStyles.menuButtonContainer}>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                edge='start'
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <>
              {isTablet && (
                <Box mr={2} className={navbarStyles.logoContainerStyle}>
                  <img
                    className={navbarStyles.logoImageStyle}
                    src={
                      organizationCurrentImage ||
                      'https://www.colorcombos.com/images/colors/FFFFFF.png'
                    }
                  />
                </Box>
              )}
              <Box className={navbarStyles.nameContainerStyle}>
                <Button
                  aria-controls='customized-menu'
                  aria-haspopup='true'
                  variant='contained'
                  color='primary'
                  onClick={showOrganizationsMenu}
                  disableElevation
                  className={navbarStyles.organizationButtonStyle}
                  endIcon={
                    currentUser?.organizations?.length > 1 && (
                      <ExpandMoreOutlinedIcon />
                    )
                  }
                >
                  {currentUser?.organization && currentUser?.organization?.name}
                </Button>
                {currentUser?.organizations?.length > 1 && (
                  <StyledMenu
                    id='customized-menu'
                    anchorEl={organizationsMenu}
                    keepMounted
                    open={Boolean(organizationsMenu)}
                    onClose={hideOrganizationsMenu}
                  >
                    {currentUser?.organizations?.map(item => {
                      return (
                        <MenuItem
                          key={item.id}
                          onClick={() => onPressOrganization(item.id)}
                        >
                          <ListItemIcon>
                            <BusinessIcon fontSize='small' />
                          </ListItemIcon>
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      )
                    })}
                  </StyledMenu>
                )}
              </Box>
            </>
          </Box>
        </Grid>
        <Grid item xs={1} sm={6} md={8}>
          <Box
            justifyContent='flex-end'
            alignItems='center'
            display='flex'
            height='100%'
            width='90%'
          >
            {isTablet && <Notification />}
            <Box>
              <Button
                className={navbarStyles.userButtonStyle}
                aria-controls='customized-menu'
                onClick={handleClick}
                aria-haspopup='true'
                variant='contained'
                disableElevation
                color='primary'
                endIcon={
                  !isTablet ? (
                    <MoreVertIcon fontSize='large' />
                  ) : (
                    <ExpandMoreOutlinedIcon />
                  )
                }
              >
                <Typography variant='subtitle1' color='secondary.light'>
                  {isTablet || isDesktop ? currentUser?.account : ''}
                </Typography>
              </Button>
              <StyledMenu
                id='customized-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => navigate('/profile')}>
                  <ListItemIcon>
                    <PersonIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Perfil de Usuario' />
                </MenuItem>
                <MenuItem onClick={onPressLogOut}>
                  <ListItemIcon>
                    <ExitToAppIcon fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Salir' />
                </MenuItem>
              </StyledMenu>
            </Box>
            {isDesktop && (
              <Box className={navbarStyles.userImageContainerStyle}>
                <img
                  className={navbarStyles.userImageStyle}
                  src={
                    userCurrentPhoto ||
                    'https://www.colorcombos.com/images/colors/FFFFFF.png'
                  }
                  onError={() => {
                    setUserCurrentPhoto(
                      'https://www.colorcombos.com/images/colors/FFFFFF.png'
                    )
                  }}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </AppBar>
  )
}

export default Navbar
